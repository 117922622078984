<template>
  <sm-categories-lists
    :breadcrumbs="breadcrumbs"
    :categories-group-name="categoriesGroupName"
    :list-name="categoriesGroupName"
  />
</template>

<script>
// components
import SmCategoriesLists from '@/components/views/SmCategoriesLists.vue';

export default {
  name: 'RequestsMain',

  components: {
    SmCategoriesLists,
  },

  data() {
    return {
      breadcrumbs: [{ text: 'Заявки' }],
      categoriesGroupName: 'requests',
      categoriesGroup: [
        {
          title: '',
          list: [
            {
              name: 'Заявки',
              route: { name: 'SupportRequests' },
              controllerName: 'SupportRequests',
            },
            {
              name: 'Мои заявки',
              route: { name: 'SupportRequestsForCurrentUser' },
              controllerName: 'SupportRequestsForCurrentUser',
            },
          ],
        },
      ],
    };
  },
};
</script>
